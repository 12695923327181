import {memo} from 'react';


import {VideoCard} from '@/components/Card';
import {VideoCardProps} from "@/components/Card/VideoCard/VideoCard.props";
import {CardsList} from '@/styles';
import {Video, VideoInPlaylist} from '@/types';

interface VideoListProps<T> {
  videos: T[];
  isLoading?: boolean;
  videoSettings?: Pick<VideoCardProps, 'withTime' | 'menu' | 'isPrivate'>;
  playlistId?: string;
}

export const VideoList = memo(
    <T extends Video | VideoInPlaylist>({videos, videoSettings, playlistId}: VideoListProps<T>) => {
      console.log(playlistId);
      return (
          <CardsList>
            {videos.map((video) => (
                <VideoCard
                    key={video.publicId}
                    movie={video}
                    to={`${location.pathname}/watch${playlistId ? '/' + playlistId + '/' : '/'}${video.publicId}?t=${video.startsFrom ?? 0}`}
                    // to={`/watch${playlistId ? '/' + playlistId + '/' : '/'}${video.publicId}?t=${video.startsFrom ?? 0}`}
                    {...videoSettings}
                />
            ))}
          </CardsList>
      );
    },
);

VideoList.displayName = 'VideoList';
