import { IconWrapper, StyledSearchInput, Wrapper } from './SearchInput.styled';

import { FocusEvent, useRef, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import SearchIcon from '@/components/SvgIcons/SearchIcon';
import { useDebounce } from '@/hooks';

export const SearchInput = ({
  placeholder,
  setSearch,
}: {
  placeholder: string;
  setSearch: (title?: string) => void;
}) => {
  const [_, setParams] = useSearchParams();

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [, setSearchInputFocus] = useState<boolean>(false);

  const makeSearch = useDebounce(() => {
    const searchInput = searchInputRef.current;
    if (searchInput) {
      setParams({ page: '1' });
      setSearch(searchInput.value);
    } else {
      setParams({});
      setSearch();
    }
  }, 500);

  const searchInputHandler = () => makeSearch();

  const searchInFocusInputHandler = (event: FocusEvent<HTMLInputElement>) => {
    if (event) {
      setSearchInputFocus(true);
    }
  };
  const searchInputBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
    if (event) {
      setSearchInputFocus(false);
    }
  };

  return (
    <Wrapper>
      <StyledSearchInput
        placeholder={placeholder}
        onChange={searchInputHandler}
        onBlur={searchInputBlurHandler}
        onFocus={searchInFocusInputHandler}
        ref={searchInputRef}
        autoComplete="off"
        icon={
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
        }
      />
    </Wrapper>
  );
};
