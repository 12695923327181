import Button from '../../Buttons/Button/Button';
import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import { Modal, Title } from '../Modal';
import { ButtonsWrapper, Inner } from '../RemovePlaylist/RemovePlaylist.styled';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { playlistsAPI, videosAPI } from '@/api';
import { useAppSelector, useActions } from '@/hooks';

export const RemoveVideo = ({ playlistId }: { playlistId?: string }) => {
  const { closeModal, showNotification } = useActions();
  const [id] = useAppSelector((state) => [state.modal.data?.id]);
  const { t } = useTranslation('components');
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [deleteMovie, result] = videosAPI.useDeleteVideoMutation();
  const [removeFromPlaylist, playlistResult] = playlistsAPI.useRemoveVideoFromPlaylistMutation();

  const showSuccess = () => {
    closeModal();
    showNotification({
      text: t('del_v', { ns: 'success' }),
      severity: 'success',
    });
  };

  const showError = () => {
    showNotification({
      text: t('del_v', { ns: 'error' }),
      severity: 'error',
    });
    setIsDelete(true);
  };

  const deleteM = () => {
    if (id && playlistId) {
      removeFromPlaylist({ videoPublicId: id, playlistId });
      'error' in playlistResult ? showError() : showSuccess();
    } else if (id) {
      deleteMovie({ id });
      'error' in result ? showError() : showSuccess();
    }
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal modal={'RemoveVideo'} closeButton>
      <Inner>
        <Title>{t('del_v', { ns: 'modal' })}</Title>
        <ButtonsWrapper>
          <Button theme={isDelete ? 'disabled' : 'red'} onClick={deleteM} disabled={isDelete}>
            {t('form.del')}
          </Button>
          <Button onClick={handleClose} theme="inline">
            {t('form.cancel')}
          </Button>
        </ButtonsWrapper>
        {result.isLoading && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
