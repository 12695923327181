export default {
  header: {
    search: 'Playlist name',
    addVideo: 'Add video',
    createPlaylist: 'Create playlist',
    menu: {
      lib: 'Library',
      stat: 'Statistics',
      set: 'Account settings',
      about: 'About',
      admin: 'Personal account',
      lang: 'Русскоязычная версия',
      logOut: 'Log out',
      in: 'Log in',
      up: 'Registration',
      search: 'Search video',
    },
  },
  pages: {
    statistics: {
      limit: 'Playlists on the page:',
      view: 'Views',
      like: 'Likes',
      repost: 'Reposts',
      comment: 'Comments',
      save: 'Saved',
      sort: 'Sort by',
      name: 'Name',
    },
    account: {
      photo: 'Change',
      personal: 'Personal account',
    },
    library: {
      no_pl:
        'There are no playlists here yet, but the ‘Create playlist’ button in the page header will help add content',
      no_v: 'There are no videos here yet, but the ‘Add video’ button in the page header will help you add content',
    },
    politic: {
      go_main: 'Go back to the main page',
    },
    main: {
      no_result: 'There are no results for your search',
      no_pl: 'Users have not shared playlists yet',
    },
    onboarding: {
      buttons: {
        nextGo: 'Next',
        go: 'Start now!',
      },
      sl1: {
        sl1_text: 'All you need',
        sl1_text2: 'for video-based learning',
      },
      sl2: {
        sl2_h1: 'Add Videos',
        sl2_text1: 'Just insert a link to a video',
        sl2_text2: 'from a popular platform*.',
        sl2_span: '*For organizations, we accept videos from any source, including hard disks.',
      },
      sl3: {
        sl3_h1: 'Move Between',
        sl3_h2: 'Segments',
        sl3_text1: 'Navigate among parts of the video using',
        sl3_text2: 'explanatory',
        sl3_word: 'timestamps.',
      },
      sl4: {
        sl4_h1: 'See Info Structure',
        sl4_text1: 'Learn by going through well-organized ',
        sl4_word: 'summaries',
      },
      sl5: {
        sl5_h1: 'Check Yourself',
        sl5_text1: 'Take ',
        sl5_text2: ' and rewatch moments with correct answers when needed.',
        sl5_word: 'tests',
      },
      sl6: {
        sl6_h1: 'Find Anything',
        sl6_word: 'Search',
        sl6_text1: ' video content like text — within individual videos or entire playlists.',
      },

      sl1_t: 'Your personal video list from favorite sources',
      sl2_save: 'Save',
      sl2_save_d: 'all the videos you like in one place',
      sl2_watch: 'Watch',
      sl2_watch_d: 'the best playlists made by others',
      sl2_share: 'Share',
      sl2_share_d: 'useful playlists with your friends',
      sl3_t: 'The library displays all added videos and your created playlists',
      sl4_t1: 'Create playlists for yourself or share with others and get special bonuses!',
      sl4_t2: 'Like, comment, and share playlists on social networks',
      sl5_tg: 'Telegram:',
      sl5_eml: 'Email:',
      innerSlider: {
        s0_n: 'Everything for UX/UI beginners',
        s0_d: 'Basic knowledge',
        s1_n: 'Film trailers',
        s1_d: 'You can quickly and easily choose what you want to watch',
        s2_n: 'Fast Recipes',
        s2_d: "Extra-fast recipes that'll save the day",
        s3_n: 'mood',
        s3_d: 'paintings and museums',
        s4_n: 'Home plants',
        s4_d: 'Lifehacks and care',
        s5_n: 'Easy home exercises',
        s5_d: 'Feel yourself at the gym',
      },
    },
    404: {
      ops: 'OOPS!',
      info: 'something went wrong...',
    },
    search: {
      title: 'all-in video search',
      input_placeholder: 'Enter the title or describe the video',
      search_button: 'Search',
      landscape: 'Landscape',
      portrait: 'Portrait',
      count_title: 'Found {{countVideo}} video',
      count_title_zero: 'Found {{count}} video',
      count_title_one: 'Found {{count}} video',
      count_title_two: 'Found {{count}} videos',
      count_title_few: 'Found {{count}} videos',
      count_title_many: 'Found {{count}} videos',
      count_title_other: 'Found {{count}} videos',
      empty_input: 'Please, enter a search term',
      header: {
        pls: 'Playlists',
        saves: 'Saved',
      },
      popup: {
        info: 'Please rate our service',
        feedback_info: 'Thank you for your feedback - it helps us improve the service',
        placeholder: 'Write your comment here',
        send: 'Send',
        thanks: 'Thanks for your feedback!',
      },
      footer: {
        title: 'Video search has never been so convenient',
        target: 'Search results even for vague queries',
        target2: 'Simultaneous search across the largest video sources',
        target3: 'Instant access to the desired fragment',
        policy: 'Policy privacy',
      },
    },
    admin: {
      timecodes_title: 'Section title',
      timecodes_text: 'Fragment`s description',
      add_answer: 'Add answer',
      title: 'Question title',
      search: {
        video: 'Search video',
        playlist: 'Search playlist',
      }
    },
  },
  components: {
    goBack: 'Back',
    goHome: 'Back to homepage',
    goNext: 'Next',
    goSkip: 'Skip',
    goStart: 'Start',
    pl_one: 'Playlist',
    pl_other: 'Playlists',
    pl_video_count: 'video',
    video_zero: 'Video',
    video_one: 'Video',
    video_two: 'Videos',
    video_few: 'Videos',
    video_many: 'Videos',
    video_other: 'Videos',
    search_in_video: 'Describe the desired video fragment',
    transcript: 'Transcript',
    timecodes: 'Timecodes',
    quiz: 'Quiz',
    summary: 'Summary',
    more: 'more',
    less: 'less',
    form: {
      name: 'Name',
      email: 'Email',
      password: 'Password',
      cur_password: 'Current password',
      new_password: 'New password',
      repeat_password: 'Repeat password',
      forgot_pass: 'Forgot password?',
      cancel: 'Cancel',
      save: 'Save',
      send: 'Send',
      del: 'Delete',
      add: 'Add',
    },
    menu: {
      set_pl: 'Playlist settings',
      del_pl: 'Delete playlist',
      del_vl: 'Delete video',
      timecodes: 'Timecodes',
      summary: 'Summary',
      quiz: 'Quiz',
    },
    breadcrumbsItems: {
      toHome: 'Home',
      toLib: 'Library',
      toPl: 'Playlist',
      toVideo: 'Video',
    },
  },
  modal: {
    reg: 'Register',
    reg_no_acc: 'Already have an account?',
    reg_info: 'By clicking "Register" you accept the',
    log_no_acc: 'No account yet?',
    add_v_to_pl: 'Add video to playlist',
    add_v_to_pl_insert: 'Add videos from your library to the playlist',
    add_v_to_pl_no_v: 'There are no videos in the library',
    video_insert: 'Insert a link to a video you like',
    video_insert_add_v: 'or insert a link to a video you like',
    video_choose: 'or choose a video from one of the sources',
    video_select_pl: 'Select the playlist the video will be saved in',
    video_no_select: 'not selected',
    category: 'Category',
    pl_name: 'Playlist name',
    pl_name_ph: 'QA tips',
    descr: 'Playlist description',
    descr_ph: 'Useful tips for everyday QA tasks',
    priv_set: 'Privacy settings',
    del_pl: 'Are you sure you want to delete this playlist?',
    del_acc: 'Are you sure you want to delete this account?',
    del_v: 'Are you sure you want to delete this video?',
    verify_email: 'Verify email address',
    verify_email_err: 'Error, account not verified',
    verify_email_suc: 'Account verified successfully',
    verify_email_info: 'An email has been sent to you at {{email}} to confirm your credentials.',
    reset_password_info: 'An email has been sent to you at  {{email}} to reset your password.',
    res_pass: 'Reset password',
    res_email: 'Email Activation',
    change_password: 'Change password',
    suggest_video: 'Add videos to playlist using AI',
    suggest: 'Video selection',
  },
  notification: {
    welcome: 'Welcome!',
  },
  options: {
    psychology: 'Psychology',
    travel: 'Travel',
    work: 'Work',
    languages: 'Languages',
    education: 'Education',
    humor: 'Humor',
    useful: 'Useful',
    recipes: 'Recipes',
    children: 'Children',
    flowers: 'Flowers',
    hobby: 'Hobby',
    sport: 'Sport',
    music: 'Music',
    cinema: 'Cinema',
    public: 'Public',
    By_link: 'By link',
    private: 'Private',
  },
  error: {
    error: 'Error, try later',
    auth: 'Authorization Error',
    refresh: 'Session expired!',
    500: 'Server not responding',
    404: 'Resource not found',
    server: 'Server error',
    fetch: 'Connection error',
    acc_inactive: 'User account is inactive',
    req_field: 'Required field',
    reg: 'Registration failed!',
    reg_error_email: 'User with this email address already exists.',
    reg_error_username: 'A user with that username already exists.',
    log: 'Log in failed',
    req_field_login: 'No account found with the given credentials',
    set_pass: 'Failed to change password!',
    set_pass_reset_lint: 'The data from the link is no longer valid.',
    field_uniq: 'Name of the playlist must be unique.',
    incor_cred: 'Incorrect credentials',
    incor_email: 'Incorrect email',
    incor_password: 'Password must be contains letters and numbers',
    inverify_email: '{{email}} is not verified',
    min_char: 'Minimum {{length}} characters',
    max_char: 'Maximum {{length}} characters',
    incor_name: 'Incorrect name',
    no_save_ch: 'Changes not saved',
    try_l: 'Try later',
    size: 'Allowed maximum size is  {{size }}Mb',
    w_video: 'Log in to watch the video',
    add_v_not_sel: 'You have not selected a video',
    add_v_empty_link: 'Please insert video link',
    add_v_uncor_link: 'The server cannot process the link, please check the link',
    del_pl: 'Playlist not deleted',
    del_acc: 'Account not deleted',
    del_v: 'Video not deleted',
    del_timecodes: 'Failed to delete timecodes',
    save_timecodes: 'Failed to save timecodes',
    res_pass: 'Reset password',
    password_no_match: "Password doesn't match",
    change_video_title: 'Title cannot be empty',
    link: 'Link not copied',
    resend: 'Send again',
    err_timecodes: 'Unfortunately, an error has occurred. Please seek assistance',
  },
  loading: {
    load_timecodes: 'The timecodes are already being generated!',
    load_quiz: 'The test are already being generated!',
  },
  success: {
    cr_pl: 'Playlist {{name}} created successfully!',
    ed_pl_name: '',
    ed_pl: '',
    logout: 'You logged out successfully!',
    registration: 'Your account has been successfully created!',
    link: 'Link copied!',
    data: 'Data changed successfully',
    format: 'Allowed file format {{format}}',
    add_video: 'The video has been successfully added to the "Video" section',
    add_video_pl: 'Video successfully added to playlist',
    add_videos_pl_n: 'Video(s) added to {{name}} playlist',
    del_pl: 'Playlist deleted successfully',
    del_acc: 'Account successfully deleted',
    del_v: 'Video deleted successfully',
    del_timecodes: 'Timecodes successfully deleted',
    save_timecodes: 'Timecodes successfully saved',
    verify_email: 'An email has been sent to you to confirm your credentials',
    set_password: 'Password changed successfully, please login',
    change_video_title: 'Title changed successfully',
  },
};
