import styled from 'styled-components';

import MyInput from '@/components/Shared/MyInput/MyInput';
import { theme } from '@/styles';

export const Wrapper = styled.div`
  position: relative;
`;
export const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 56%;
  transform: translateY(-50%);

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const StyledSearchInput = styled(MyInput)`
  & input {
    padding-left: 50px;
  }

  @media screen and (max-width: 1024px) {
    & input {
      padding-left: 20px;
      background: ${theme.colors.blue.blue_modal};
    }
  }
`;
