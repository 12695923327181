import { Wrapper, TitleTimeWrapper, InnerWrapper } from './TitleTime.styled';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHookFormMask } from 'use-mask-input';

import { TrashButton } from '@/components';
import MyInput from '@/components/Shared/MyInput/MyInput';
import { Timecode } from '@/types';

type TimecodesProps = {
  index: number;
  remove: () => void;
};

export const TitleTime = ({ index, remove }: TimecodesProps) => {
  const { t } = useTranslation('pages');

  const { register } = useFormContext<{ timecodes: Timecode[] }>();
  const registerWithMask = useHookFormMask(register);

  const {
    formState: { errors },
  } = useFormContext<{ timecodes: Timecode[] }>();
  return (
    <Wrapper>
      <InnerWrapper>
        <TitleTimeWrapper>
          <MyInput
            {...register(`timecodes.${index}.title`, { required: true })}
            placeholder={t('admin.timecodes_title')}
            error={errors.timecodes?.[index]?.title}
            showErrorMessage={false}
          />
          <div>
            <MyInput
              {...registerWithMask(`timecodes.${index}.start`, '99:99:99', {
                required: true,
                pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
                validate: (value, formValue) => {
                  if (
                    index + 1 === formValue.timecodes?.length &&
                    value < formValue.timecodes?.[index - 1]?.startOffsetMs
                  ) {
                    return false;
                  }
                  if (value && value > formValue.timecodes?.[index + 1]?.startOffsetMs) {
                    return false;
                  }
                  return true;
                },
              })}
              placeholder="00:00:00"
              style={{ maxWidth: '130px', padding: '12px 16px', overflow: 'hidden' }}
              error={errors.timecodes?.[index]?.start}
              showErrorMessage={false}
            />
          </div>
        </TitleTimeWrapper>
        <MyInput
          {...register(`timecodes.${index}.text`, { required: true })}
          placeholder={t('admin.timecodes_text')}
          error={errors.timecodes?.[index]?.text}
          showErrorMessage={false}
        />
      </InnerWrapper>

      <TrashButton bg="outline" onClick={remove} />
    </Wrapper>
  );
};
