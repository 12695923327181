import { api } from './api';

import type {
  TimecodesResponse,
  TransformedTimecodesResponse,
  BaseAdminRequest,
  BaseTimecodesRequest,
  GetList,
  Transcript,
  SummariesRequest
} from '@/types';

import { formatDuration } from '@/utils';


const PATH = 'control-panels';

export const adminAPI = api.injectEndpoints({
  endpoints: (build) => ({
    // таймкоды
    getTimecodesAdmin: build.query<TransformedTimecodesResponse, BaseAdminRequest>({
      query: ({ videoPk }) => ({
        url: `${PATH}/video/${videoPk}/timecodes/`,
        method: 'GET',
      }),
      transformResponse: (response: TimecodesResponse) => {
        const timecodes = response.results[0] && response.results[0].data.timecodes
          .filter((obj, index) => {
            return (
              index ===
              response.results[0].data.timecodes.findIndex((t) => t.start === obj.start || t.text === obj.text)
            );
          })
          .map((timecode) => ({ ...timecode, start: formatDuration(Math.round(timecode.start as number)) }));


        const publicId = response.results[0].publicId; // Извлекаем publicId из результата



        return { timecodes, publicId }; // Возвращаем как объект с обновленной структурой
      },
    }),
    putTimecodesAdmin: build.mutation<void, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/video/${videoPk}/timecodes/${publicId}/`,
        method: 'PUT',
        body,
      }),
    }),
    patchTimecodesAdmin: build.mutation<void, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/video/${videoPk}/timecodes/${publicId}/`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteTimecodesAdmin: build.mutation<void, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ videoPk, publicId }) => ({
        url: `${PATH}/video/${videoPk}/timecodes/${publicId}/`,
        method: 'DELETE',
      }),
    }),

    // квизы
    getQuizzesAdmin: build.query<any, { playlistPk: string }>({
      query: ({ playlistPk }) => ({
        url: `${PATH}/playlist/${playlistPk}/quizzes/`,
        method: 'GET',
      }),
    }),

    // саммари
    getSummaryAdmin: build.query<any, SummariesRequest>({
      query: ({ playlistPk, videoPk }) => ({
        url: `${PATH}/playlist/${playlistPk}/video/${videoPk}/summaries/`,
        method: 'GET',
      }),
    }),
    deleteSummaryAdmin: build.mutation<void, SummariesRequest>({
      query: ({ playlistPk, videoPk }) => ({
        url: `${PATH}/playlist/${playlistPk}/video/${videoPk}/summaries/`,
        method: 'DELETE',
      }),
    }),

    // транскрипты
    getTranscriptsAdmin: build.query<GetList<Transcript>, BaseAdminRequest>({
      query: ({ videoPk }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/`,
        method: 'GET',
      }),
    }),

    getOneTranscriptAdmin: build.query<Transcript, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ publicId, videoPk }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/${publicId}/`,
        method: 'GET',
      }),
    }),
    putTranscriptsAdmin: build.mutation<Transcript, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/${publicId}/`,
        method: 'PUT',
        body,
      }),
    }),
    patchTranscriptsAdmin: build.mutation<Transcript, BaseTimecodesRequest>({
      query: ({ videoPk, publicId, body }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/${publicId}/`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteTranscriptsAdmin: build.mutation<void, Omit<BaseTimecodesRequest, 'body'>>({
      query: ({ videoPk, publicId }) => ({
        url: `${PATH}/video/${videoPk}/transcripts/${publicId}/`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetTimecodesAdminQuery,
  useLazyGetTimecodesAdminQuery,
  usePutTimecodesAdminMutation,
  usePatchTimecodesAdminMutation,
  useDeleteTimecodesAdminMutation,
  useGetSummaryAdminQuery,
  useDeleteSummaryAdminMutation,
  useGetQuizzesAdminQuery,
  useGetTranscriptsAdminQuery,
  useGetOneTranscriptAdminQuery,
  usePutTranscriptsAdminMutation,
  usePatchTranscriptsAdminMutation,
  useDeleteTranscriptsAdminMutation,
} = adminAPI;
