import { SwitchItem, SwitchTitle, Wrapper } from './TabsSwitcherStyle';

import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export const TabSwitcher = memo(({ rootPath }: { rootPath: 'library' | 'account/view' }) => {
  const { t } = useTranslation('components');

  const location = useLocation();

  return (
    <Wrapper switchPosition={location.pathname === `/${rootPath}` ? 0 : 1}>
      <Link to=".">
        <SwitchItem isActive={location.pathname === `/${rootPath}`}>
          <SwitchTitle>{t('pl', { count: 101 })}</SwitchTitle>
        </SwitchItem>
      </Link>
      <Link to="./videos">
        <SwitchItem isActive={location.pathname === `/${rootPath}/videos`}>
          <SwitchTitle>{t('video', { count: 101 })}</SwitchTitle>
        </SwitchItem>
      </Link>
    </Wrapper>
  );
});

TabSwitcher.displayName = 'TabSwitcher';
