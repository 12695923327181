import {VideoCardProps} from './VideoCard.props';

import DropDown from '../../Shared/DropDown';
import DropDownMenuItem from '../../Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import DotsVerticalIcon from '../../SvgIcons/DotsVerticalIcon';
import PlayIcon from '../../SvgIcons/PlayIcon';
import TrashIcon from '../../SvgIcons/TrashIcon';
import {ImageWrapper, Title} from '../PlaylistCard';

import {memo, Reducer, useReducer} from 'react';

import {useTranslation} from 'react-i18next';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';

import {playlistsAPI, useLazyGetDocsQuery, useLazyGetTimecodesQuery} from '@/api';
import {InnerCard, PlayButton, VideoCardWrapper, VideoMenu, VideoTime} from '@/components/Card';
import DiaryIcon from '@/components/SvgIcons/DairyIcon';
import NotepadIcon from '@/components/SvgIcons/NotepadIcon';
import StopWatchIcon from '@/components/SvgIcons/Stopwatch';
import {useActions, useAppSelector} from '@/hooks';

const VideoCard = ({ movie, menu = false, withTime = false, isPrivate = false, to }: VideoCardProps) => {
  const { title, thumbnailUrl, publicId } = movie;
  const { openModal } = useActions();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('components');
  const { showNotification } = useActions();
  const { hash } = useParams();
  const [isAuth] = useAppSelector((state) => [state.user.isAuth, state.user.user_id]);

  const {
    data: playlistPrivatelink,
  } = playlistsAPI.useReadPrivateLinkQuery({ linkHash: hash || '' }, { skip: !hash || !isAuth });

  // const { id: playlistId } = useParams();
  const privateLink = location.pathname.startsWith('/private/')
  const playlistIdPrivatelink = playlistPrivatelink && playlistPrivatelink.publicId

  const playlistId = location.pathname.split('/')[2];

  const [getDocs] = useLazyGetDocsQuery();
  const [getTimecodes] = useLazyGetTimecodesQuery();

  const getTimecodesHandle = async () => {
    // console.log(timecodes);
    // if (timecodes.isError) {
    //   showNotification({ text: `Не получилось получить таймкоды. Попробуйте чуть позже`, severity: 'error' });
    // }
    if (privateLink) {
      await getTimecodes({ playlistId: playlistIdPrivatelink || '', videoPublicId: publicId });
      navigate(`${location.pathname}/watch${playlistIdPrivatelink  ? '/' + playlistIdPrivatelink + '/' : '/'}${publicId}`);
    } else {
      await getTimecodes({ playlistId: playlistId, videoPublicId: publicId });
      navigate(`watch/${publicId}`);
    }
  };

  const getSummaryHandle = async () => {
    let summary;
    if (privateLink) {
       summary = await getDocs({ playlistId: playlistIdPrivatelink || '', videoPublicId: publicId });
    } else {
       summary = await getDocs({ playlistId: playlistId, videoPublicId: publicId });
    }
    if (summary.isError) {
      showNotification({ text: `Не получилось скачать конспект. Попробуйте чуть позже`, severity: 'error' });
    }

    if (summary.data) {
      const a = document.createElement('a');
      a.href = summary.data;
      a.target = '_blanc';
      a.download = `${publicId}.pdf`;

      a.click();
    }
  };

  const openQuiz = () => {
    if (privateLink) {
      navigate(`${location.pathname}/quiz/${publicId}`, { state: { playlistId: playlistIdPrivatelink } });
    } else {
      navigate(`quiz/${publicId}`, { state: { playlistId: playlistId } });
    }
  };

  const dropDownMenuItems = !isPrivate && !privateLink
    ? [
        <DropDownMenuItem
          key={5}
          icon={<TrashIcon />}
          text={t('menu.del_vl')}
          onClick={() =>
            openModal({
              active: 'RemoveVideo',
              data: {
                id: publicId,
                playlistId,
              },
            })
          }
        />,
      ]
    : [
        // <DropDownMenuItem key={1} icon={<SettingsIcon />} text={'Настройки видео'} onClick={() => openModal('EditPlayList')}/>,
        <DropDownMenuItem key={2} icon={<StopWatchIcon />} text={t('menu.timecodes')} onClick={getTimecodesHandle} />,
        <DropDownMenuItem key={3} icon={<DiaryIcon />} text={t('menu.summary')} onClick={getSummaryHandle} />,
        <DropDownMenuItem key={4} icon={<NotepadIcon />} text={t('menu.quiz')} onClick={openQuiz} />,
       !privateLink && <DropDownMenuItem
          key={5}
          icon={<TrashIcon />}
          text={t('menu.del_vl')}
          onClick={() =>
            openModal({
              active: 'RemoveVideo',
              data: {
                id: publicId,
                playlistId,
              },
            })
          }
        />,
      ];

  const [opened, setOpened] = useReducer<Reducer<boolean, boolean>>((v: boolean) => !v, false);
  const toggleHandler = () => {
    setOpened(opened);
  };

  return (
    <VideoCardWrapper>
      <Link
        to={to ?? `watch/${publicId}?t=${movie.startsFrom ?? 0}`}
        state={`${location.pathname}`}
        style={{ cursor: 'pointer' }}
      >
        <ImageWrapper bgImage={thumbnailUrl}>
          <PlayButton>
            <PlayIcon />
          </PlayButton>
          {withTime && (
            <VideoTime>
              {new Date(movie.startsFrom ?? 0).toLocaleTimeString('ru-RU', {
                second: '2-digit',
                minute: '2-digit',
              })}
            </VideoTime>
          )}
        </ImageWrapper>
      </Link>
      {/* <DescriptionMenuWrapper>
          </DescriptionMenuWrapper> */}
      <InnerCard>
        <Title>{title.slice(0, 100)}</Title>

        <VideoMenu>
          {(menu || privateLink) && (
            <DropDown items={dropDownMenuItems} isOpen={opened} toggleOpen={toggleHandler}>
              <DotsVerticalIcon />
            </DropDown>
          )}
        </VideoMenu>
      </InnerCard>
    </VideoCardWrapper>
  );
};

export default memo(VideoCard);
