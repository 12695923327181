import { Inner } from './AdminPage.styled';
import { SearchInput } from './SearchInput';

import { CardItem, CardList } from '../LibraryPage';

import { useEffect } from 'react';

import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { playlistsAPI } from '@/api';
import { FullScreenLoader } from '@/components';
import { UserPlaylistCard } from '@/components/Card';
import { useAppSelector, useHandlingError, useMatchMedia, usePagination } from '@/hooks';
import { PaginationWrapper } from '@/styles';

export const AdminPlaylists = () => {
  const { t } = useTranslation('pages');
  const { catchError } = useHandlingError();
  const { isAuth: auth, username, avatar } = useAppSelector((state) => state.user);
  const {
    pagination: { pages, page },
    handleChangePage,
    setCount,
  } = usePagination();

  const isDesktop = useMatchMedia('(min-width: 1025px)');

  const {
    data: playlists,
    isFetching,
    isLoading,
    isSuccess,
    error,
  } = playlistsAPI.useGetMyPlaylistsQuery({ params: { page } }, { skip: !auth });

  const [getMyPl] = playlistsAPI.useLazyGetMyPlaylistsQuery();

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error]);

  useEffect(() => {
    setCount(playlists?.count);
  }, [playlists]);

  const setSearch = async (title?: string) => {
    await getMyPl({ params: { title } });
  };

  return (
    <>
      <SearchInput placeholder={t('admin.search.playlist')} setSearch={setSearch} />
      <Inner>
        {(isLoading || isFetching) && <FullScreenLoader />}
        {isSuccess && (
          <>
            <CardList>
              {playlists?.results.map((item, i) => (
                <CardItem key={i}>
                  <UserPlaylistCard playlist={item} name={username.slice(0, 10)} avatar={avatar} isAdmin />
                </CardItem>
              ))}
            </CardList>
            {playlists?.results.length > 0 && (
              <PaginationWrapper>
                {pages != 1 && (
                  <Pagination
                    count={pages}
                    page={page}
                    size={isDesktop ? 'medium' : 'small'}
                    color="primary"
                    onChange={handleChangePage}
                  />
                )}
              </PaginationWrapper>
            )}
          </>
        )}
      </Inner>
    </>
  );
};
